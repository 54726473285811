import { combineReducers } from "redux";
import { commonReducer } from "./common/commonReducer";
import { complaintsReducer } from "./complaints/complaintsReducer";
import { customizerReducer } from "./customizer/customizerReducer";
import { dashboardReducer } from "./dashboard/dashboardReducer";
import { IStoreState } from "./initialStoreState";
import { registeredUsersReducer } from "./registeredUsers/registeredUsersReducer";
import { servicesReducer } from "./sowService/serviceReducer";
import { sowDashboardReducer } from "./sowDashboard/dashboardReducer";

import { onlineDashboardReducer } from "./onlineDashboard/dashboardReducer";
import { PromoReducer } from "./PromoCode/PromoReducer";
import { productReducer } from "./Product";

const appReducer = combineReducers({
  customizerReducer: customizerReducer,
  common: commonReducer,
  registeredUsers: registeredUsersReducer,
  dashboard: dashboardReducer,
  sowDashboard: sowDashboardReducer,
  complaints: complaintsReducer,
  services: servicesReducer,
  product: productReducer,
  promo: PromoReducer, // Add this line
  onlineDsa: onlineDashboardReducer,
});

export const rootReducer = (state: IStoreState | undefined, action: any) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
